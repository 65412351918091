export const format = (value, positions = 2) => {
  return parseFloat(value).toFixed(positions);
};

export const formatCents = (value, positions = 2) => {
  return format((value || 0) / 100, positions);
};

export const formatWithCurrency = (value, positions = 2, currency = '€') => {
  return format((value || 0), positions) + currency;
};

export const formatCentsWithCurrency = (value, positions = 2, currency = '€') => {
  return formatCents((value || 0), positions) + currency;
};
