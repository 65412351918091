<template>
  <Page class="page-purchases page-list-purchase">
    <template #title>Acquisti</template>

    <template #default>
      <template v-if="purchases && purchases.length > 0">
        <div class="d-flex flex-row justify-content-end mb-5">
          <button type="button" class="btn btn-outline-primary" @click.prevent="exportPurchases">Esporta acquisti</button>
        </div>

        <AwlTable :heading="tableHeadings" :rows="purchases" striped>
          <template #column-key-shop="{ value }">
            <template v-if="value">{{ value.name }}</template>
            <span v-else></span>
          </template>

          <template #column-key-item_image="{ row }">
            <template v-if="row.is_item_purchase">
              <template v-if="((row.item || {}).cover || {}).thumbUrl">
                <img :src="row.item.cover.thumbUrl" :alt="row.itemName">
              </template>
              <template v-else-if="((row.shop || $oauth2.me.shop || {}).cover || {}).thumbUrl">
                <img :src="(row.shop || $oauth2.me.shop).cover.thumbUrl" :alt="row.shopName">
              </template>
              <span v-else></span>
            </template>
            <template v-else>
              <template v-if="((row.shop || $oauth2.me.shop || {}).cover || {}).thumbUrl">
                <img :src="(row.shop || $oauth2.me.shop).cover.thumbUrl" :alt="row.shopName">
              </template>
              <span v-else></span>
            </template>
          </template>

          <template #column-key-item="{ row }">
            <template v-if="row.is_item_purchase">{{ row.itemName }}</template>
            <template v-else>Pagamento diretto</template>
          </template>

          <template #column-key-amount_paid="{ value }">{{ value | currency }}</template>
          <template #column-key-merchant_amount="{ value }">{{ value | currency }}</template>
          <template #column-key-total_fee="{ value }">{{ value | currency }}</template>

          <template #column-key-created_at="{ value }">{{ value | date }}</template>

          <template #column-key-paymentMethod="{ value }">
            <template v-if="value === 'bank-transfer'">Bonifico</template>
            <template v-else>Carta di credito</template>
          </template>

          <template #column-key-payment_status="{ value }">
            <template v-if="value === 'pending'">In attesa</template>
            <template v-else-if="value === 'rejected'">Rifiutato</template>
            <template v-else-if="value === 'completed'">Completato</template>
            <template v-else>-</template>
          </template>

          <template #column-key-redeemed="{ row }">
            <template v-if="!row.redeemRequired">-</template>
            <template v-else>
              {{ row.redeemed ? 'Sì' : 'No' }}
            </template>
          </template>

          <template #column-key-shipping="{ row }">
            {{ row.withShipping ? 'Sì' : 'No' }}
          </template>

          <template #column-key-invoice="{ row }">
            {{ row.withInvoice ? 'Sì' : 'No' }}
          </template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'purchases.show', params: { id: row.id } }" />
          </template>
        </AwlTable>

        <div class="row justify-content-end align-items-center">
          <div class="col-auto text-right">
            <p>
              Risultati da mostrare
              <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
            </p>
          </div>
        </div>

        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessun acquisto presente</Alert>
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import pageableMixin from '@/libs/Pagination/mixins/pageable.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';
import rolesMixin from '@/mixins/roles.js';
import formatCurrency from '@/filters/formatCurrency.js';
import formatDate from '@/filters/formatDate.js';
import saveStringAsFile from '@/helpers/saveStringAsFile.js';

import Page from '@/views/components/Private/Page.vue';
import AwlPagination from '@/libs/Pagination/components/AwlPagination.vue';
import PerPageSelector from '@/libs/Pagination/components/PerPageSelector.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listPurchases()
    .then(res => next(vm => {
      vm.purchases = res?.data?.purchases || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [pageableMixin, confirmWithModalMixin, rolesMixin],
  components: {
    Page,
    AwlPagination,
    PerPageSelector,
    AwlTable,
    ActionShow,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  filters: {
    currency: formatCurrency,
    date: formatDate,
  },
  data: () => ({
    purchases: [],
  }),
  computed: {
    tableHeadings () {
      return [
        { key: 'item_image', label: '' },
        { key: 'shop', label: 'Negozio', hidden: !this.isAdmin },
        { key: 'item', label: 'Offerta' },
        { key: 'paymentMethod', label: 'Pagamento' },
        { key: 'payment_status', label: 'Stato' },
        { key: 'created_at', label: 'Data' },
        { key: 'amount_paid', label: 'Importo' },
        { key: 'merchant_amount', label: 'Quota merchant' },
        { key: 'total_fee', label: 'Quota eziCommerce' },
        { key: 'redeemed', label: 'Riscattato' },
        { key: 'invoice', label: 'Ricevuta' },
        { key: 'shipping', label: 'Spedizione' },
      ];
    },
  },
  methods: {
    fetchResults (page = this.page) {
      return $api.listPurchases(page, this.perPage)
        .then(res => {
          this.purchases = res?.data?.purchases || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
    exportPurchases () {
      this.$api.exportPurchases('csv')
        .then(res => res.data)
        .then(csvRows => {
          saveStringAsFile('ezicommerce-purchases.csv', csvRows);
        })
        .catch(err => this.$log.error(err))
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
