var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticClass:"page-purchases page-list-purchase",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Acquisti")]},proxy:true},{key:"default",fn:function(){return [(_vm.purchases && _vm.purchases.length > 0)?[_c('div',{staticClass:"d-flex flex-row justify-content-end mb-5"},[_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.exportPurchases.apply(null, arguments)}}},[_vm._v("Esporta acquisti")])]),_c('AwlTable',{attrs:{"heading":_vm.tableHeadings,"rows":_vm.purchases,"striped":""},scopedSlots:_vm._u([{key:"column-key-shop",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(value.name))]:_c('span')]}},{key:"column-key-item_image",fn:function(ref){
var row = ref.row;
return [(row.is_item_purchase)?[(((row.item || {}).cover || {}).thumbUrl)?[_c('img',{attrs:{"src":row.item.cover.thumbUrl,"alt":row.itemName}})]:(((row.shop || _vm.$oauth2.me.shop || {}).cover || {}).thumbUrl)?[_c('img',{attrs:{"src":(row.shop || _vm.$oauth2.me.shop).cover.thumbUrl,"alt":row.shopName}})]:_c('span')]:[(((row.shop || _vm.$oauth2.me.shop || {}).cover || {}).thumbUrl)?[_c('img',{attrs:{"src":(row.shop || _vm.$oauth2.me.shop).cover.thumbUrl,"alt":row.shopName}})]:_c('span')]]}},{key:"column-key-item",fn:function(ref){
var row = ref.row;
return [(row.is_item_purchase)?[_vm._v(_vm._s(row.itemName))]:[_vm._v("Pagamento diretto")]]}},{key:"column-key-amount_paid",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("currency")(value)))]}},{key:"column-key-merchant_amount",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("currency")(value)))]}},{key:"column-key-total_fee",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("currency")(value)))]}},{key:"column-key-created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"column-key-paymentMethod",fn:function(ref){
var value = ref.value;
return [(value === 'bank-transfer')?[_vm._v("Bonifico")]:[_vm._v("Carta di credito")]]}},{key:"column-key-payment_status",fn:function(ref){
var value = ref.value;
return [(value === 'pending')?[_vm._v("In attesa")]:(value === 'rejected')?[_vm._v("Rifiutato")]:(value === 'completed')?[_vm._v("Completato")]:[_vm._v("-")]]}},{key:"column-key-redeemed",fn:function(ref){
var row = ref.row;
return [(!row.redeemRequired)?[_vm._v("-")]:[_vm._v(" "+_vm._s(row.redeemed ? 'Sì' : 'No')+" ")]]}},{key:"column-key-shipping",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.withShipping ? 'Sì' : 'No')+" ")]}},{key:"column-key-invoice",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.withInvoice ? 'Sì' : 'No')+" ")]}},{key:"actions-content",fn:function(ref){
var row = ref.row;
return [_c('ActionShow',{attrs:{"to":{ name: 'purchases.show', params: { id: row.id } }}})]}}],null,false,1963811465)}),_c('div',{staticClass:"row justify-content-end align-items-center"},[_c('div',{staticClass:"col-auto text-right"},[_c('p',[_vm._v(" Risultati da mostrare "),_c('PerPageSelector',{staticClass:"d-inline w-auto",attrs:{"options":_vm.perPageOptions},on:{"input":function($event){return _vm.fetchResults(1)}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)])]),_c('AwlPagination',{attrs:{"value":_vm.page,"totalPages":_vm.totalPages},on:{"page":_vm.goToPage}})]:_c('Alert',{attrs:{"status":"info"}},[_vm._v("Nessun acquisto presente")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }